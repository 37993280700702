import { Directive, AfterViewInit, AfterViewChecked } from '@angular/core';
import feather from 'feather-icons';

@Directive({
  selector: '[appFeatherIcon]'
})
export class FeatherIconDirective implements AfterViewChecked {

  constructor() { }

  ngAfterViewChecked() {
    // feather icon
    feather.replace();
  }

}
